import React from "react";
import { useLanguage } from "../../../languageContext";

const LoadMore = ({ onClickHandler, buttonText, show, loading = false }) => {
  console.log("filter jobs", show);
  const currentLanguage = useLanguage();
  return (
    <div className="load-more-btn-wrapper para">
      {!show ? (
        <>
          {loading && <div className="loader"></div>}
          <button
            className={`load-more-btn border-${
              currentLanguage ? currentLanguage : ""
            }`}
            onClick={onClickHandler}
          >
            {buttonText}
          </button>
        </>
      ) : (
        <p className={`para text-center color-${currentLanguage}`}>End</p>
      )}
    </div>
  );
};

export default LoadMore;
